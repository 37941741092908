"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RECAPTCHA_SITE_KEY = exports.DEVELOPERS_SUPPORT_EMAIL = exports.COPILOT_IDV2_REDIRECTION = exports.COPILOT_ADMIN_SUNSET_V1 = exports.USER_PROFILE_CHANGED = exports.API_ERROR_MESSAGE = exports.NAVIGATION_ID_NUMBER = exports.LAST_NAME_MAX_LENGTH = exports.FIRST_NAME_MAX_LENGTH = exports.REQUIRED_PASSWORD_LENGTH = exports.REQUIRED_EMAIL_LENGTH = exports.NEW_TERMINOLOGY_UPDATE = exports.HOME_USER_LIST = exports.HOME_ACTIVITY = exports.MY_ACCOUNT_ROUTE = exports.ADMIN_USER_LIST = exports.ADMIN_ACTIVITY = exports.ADMIN_ROUTE = exports.FORGOT_PASSWORD_ROUTE = exports.LOCAL_AUTH_FAILED = exports.USER_NOT_FOUND = exports.REQUEST_VALIDATION = exports.ACCOUNT_NOT_FOUND = exports.TOKEN_INVALID = exports.AUTH = exports.NAV_LOGIN_V2 = exports.API_UPDATE_USER = exports.API_FORGOT_PASSWORD = exports.API_LOGIN_USER = exports.API_ACTIVE_SESSION = void 0;
exports.API_ACTIVE_SESSION = 'user/activation';
exports.API_LOGIN_USER = 'auth/local/login';
exports.API_FORGOT_PASSWORD = 'auth/local/forgot-password';
exports.API_UPDATE_USER = 'user/update';
exports.NAV_LOGIN_V2 = '/auth/v2/login';
exports.AUTH = '/auth';
exports.TOKEN_INVALID = 'TOKEN_INVALID';
exports.ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND';
exports.REQUEST_VALIDATION = 'REQUEST_VALIDATION';
exports.USER_NOT_FOUND = 'USER_NOT_FOUND';
exports.LOCAL_AUTH_FAILED = 'LOCAL_AUTH_FAILED';
exports.FORGOT_PASSWORD_ROUTE = '/auth/forgot-password';
exports.ADMIN_ROUTE = '/admin';
exports.ADMIN_ACTIVITY = '/admin/activity';
exports.ADMIN_USER_LIST = '/admin/users-list';
exports.MY_ACCOUNT_ROUTE = '/auth/my-account';
exports.HOME_ACTIVITY = '/home/activity';
exports.HOME_USER_LIST = '/home/users-list';
exports.NEW_TERMINOLOGY_UPDATE = 'copilot-authentication-url-update';
exports.REQUIRED_EMAIL_LENGTH = 8;
exports.REQUIRED_PASSWORD_LENGTH = 8;
exports.FIRST_NAME_MAX_LENGTH = 40;
exports.LAST_NAME_MAX_LENGTH = 40;
exports.NAVIGATION_ID_NUMBER = 1;
exports.API_ERROR_MESSAGE = 'An error has occurred';
exports.USER_PROFILE_CHANGED = 'USER_PROFILE_CHANGED';
exports.COPILOT_ADMIN_SUNSET_V1 = 'show-sunset-banner';
exports.COPILOT_IDV2_REDIRECTION = 'copilot-authentication-idv2-redirection';
exports.DEVELOPERS_SUPPORT_EMAIL = 'developers@fabric.inc';
exports.RECAPTCHA_SITE_KEY = '6LfQGIUlAAAAAGxQrDCeA2YOjUHAUBuiZpTcsVPy';
